@import 'paper-dashboard/variables';

@font-face {
 font-family: "Bench";
 src: url("../fonts/OpenSans-Light.ttf") format("truetype");
}

@font-face {
 font-family: "Condense";
 src: url("../fonts/BenchNine-Light.ttf") format("truetype");
}

$bg-active : rgba(33,33,33,1) !default;

// scnf colors palette
$color-yellow: #ffdc00;
$color-blue: #1f367b;
$color-text: #000;
$color-text-title: #ffffff;
$color-select: #DDD;
$color-dark-select: #333;

input, button:focus {
    outline:none;
}

#root {
  overflow: hidden;
  color:$color-text;
  font-family: "Bench";

  h2 {
    color:$color-text-title;
    font-weight: 600;
  }
}

.loginWindow {
  color:'#FFF' !important;
  width:100vw;
  height:100vh;
  padding:40px;
  background-image: url('../img/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.loginSubtitle {
  color:$color-yellow;
}

.loginLabel {
  font-size: 1.5em;
  justifyContent:center;
  alignItems:center;
  color:#FFF;
}

.textOnWhite {
  font-size: 1.5em;
  justifyContent:center;
  alignItems:center;
  color:$color-blue;
}


.avatarName {
  font-weight: 600;
  color: #FFF;
  font-size: 1.3em;
  text-align: center;
  height:20%;
}

.avatarInitiale {
  color:$color-blue;
  font-size:5em;
  font-weight:bold;
  width:80%;
  height:80%;
  background-color:white;
  border-radius:16px;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-bottom:12px;
}

.btnRound {
  border: none;
  border-radius: 16px;
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  color:$color-blue;
  font-size: 1.2em;
  padding:8px;
  padding-left:12px;
  padding-right:10px;
  margin:0;
  cursor:pointer;
}

.btnRound:hover {
  background-color:$color-yellow;
}

.btnRound:disabled {
  background-color:$color-select;
  opacity:0.9;
}

.checkLineLabel {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  flex:1;
}

.checkLine {
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
}

.checkLine:hover {
  background-color: lighten($color-yellow,20%);
}

.selected {
  background-color: lighten($color-yellow,20%);
}

.dark_checkLine {
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
}

.dark_checkLine:hover {
  background-color: $color-dark-select;
}

.dark_selected {
  background-color: $color-dark-select;
}

.plateSelect {
  position: absolute !important;
  border:none !important;
}

.react-select .react-select__control {
  border:none !important;
}

.selectPlateOption {
  max-height: 300px;
  overflow: auto;

  ul {
    margin:0px;
    padding:0px;
  }
  li:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  li:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  li {
    list-style:none;
    padding:10px;
    font-size: 2.5em;
  }

  .selected {
    background-color:$color-select;
  }
}

.tooltip-inner {
  padding:0;
  max-width: 400px;
}

.footerPeugeotInfo {
  display:flex;
  flex:7;
  flex-direction:column;
  border:solid 2px #1f367b;
  border-radius:20px;
  box-shadow: 3px 3px 0px 0px #1f367b;
  margin-right:20px;
  height:110px;
}

.opelFooterCol {
  display:flex;
  flex-direction:column;
  flex:1;
  padding-right:40px;
}

.layoutBarTitle {
  color:$color-blue;
  font-size:1.3em;
  font-weight: 600;
}

.underscore {
  height:2px;
  background-color: $color-blue;
  width:100%;
}

.carCompanySelect {
  opacity:0.6;
}

.carCompanySelect:hover {
  opacity:1;
}

.selectRole {
  background-color: white;
  border-radius: 12px;
  width:220px;
}

.react-select.primary .react-select__control .react-select__value-container--has-value .react-select__single-value,
.react-select.primary .react-select__control .react-select__value-container--has-value .react-select__multi-value__label {
  color:$color-blue !important;
  font-size: 1.5em;
}

h3 {
  color:$color-text-title;
}
